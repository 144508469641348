
import { createInertiaApp } from '@inertiajs/svelte'
import { mount } from 'svelte'

import "@fontsource/open-sans";
import "@fontsource-variable/montserrat";

import Pusher from 'pusher-js';
window.Pusher = Pusher;

import Echo from 'laravel-echo';
window.Echo = new Echo({
    broadcaster: 'reverb',
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT ?? 80,
    wssPort: import.meta.env.VITE_REVERB_PORT ?? 443,
    wsPath: import.meta.env.VITE_REVERB_PATH ?? "/",
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
});

createInertiaApp({
    id: 'wrapper',
    resolve: async name => {
        const pages = import.meta.glob('./Pages/**/*.svelte')
        const page = await pages[`./Pages/${name}.svelte`]()
        const Layout = (await import('./layout/Layout')).default;
        return { default: page.default, layout: page.default.layout || Layout }
    },
    setup({ el, App, props }) {
        mount(App, { target: el, props })
    },
    progress: {
        color: '#0F75BC',
        showSpinner: true,
        includeCSS: false
    }
})